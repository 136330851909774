<template>
  <div class="app-container">
    <el-card>
      <div slot="header" class="card-header">
        <h3 style="margin: 0;">Evaluation Rating Maintenance</h3>
      </div>
        <el-row :gutter="5">
          <el-col :span="3">
            <el-select v-model="selectedYear" size="mini" @change="getEval" placeholder="Year">
              <el-option  v-for="year in yearList" :key="year.id" :value="year.year" :label="year.year"></el-option>
            </el-select>
          </el-col>
          <el-col :span="4">
            <el-select v-model="selectedEval" size="mini" placeholder="Evaluation Name" :disabled="selectedYear === ''" @change="getPercentage">
              <el-option  v-for="evaluation in evaluations" :key="evaluation.id" :value="evaluation.id" :label="evaluation.name"></el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row>
          <h2>Percentage</h2>
        </el-row>
        <el-row :gutter="30">
          <el-col :span="12">
            <el-input v-model="addNoMonthsPercentage" size="mini" placeholder="No of Months" style="width: 25%;" type="number"></el-input>
            <el-table
              :data="percentageTable"
              v-loading="loadingPercent"
              >
              <el-table-column
                prop="percent_rating_from"
                label="From (MPP Rating)"
                align="center"
                >
                <template slot="header" slot-scope="scope">
                  <div>
                    <span>From (MPP Rating)</span>
                    <el-input size="mini" type="number" @change="hello(scope)" v-model="addFromPercentage"></el-input>
                  </div>
                </template>
                <template slot-scope="scope">
                  <div>
                    <span v-if="!scope.row.isEdit">{{scope.row.percent_rating_from}}</span>
                    <el-input v-model="scope.row.percent_rating_from" v-else type="number"></el-input>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="percent_rating_to"
                label="To (MPP Rating)"
                align="center"
                >
                <template slot="header" slot-scope="scope">
                  <div>
                    <span>To (MPP Rating)</span>
                    <el-input size="mini" type="number" @change="hello(scope)" v-model="addToPercentage"></el-input>
                  </div>
                </template>
                <template slot-scope="scope">
                  <div>
                    <span v-if="!scope.row.isEdit">{{scope.row.percent_rating_to}}</span>
                    <el-input v-model="scope.row.percent_rating_to" v-else type="number"></el-input>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="percent_rating_value"
                label="Value"
                align="center"
                >
                <template slot="header" slot-scope="scope">
                  <div>
                    <span>Value</span>
                    <el-input size="mini" type="number" @change="hello(scope)" v-model="addValuePercentage"></el-input>
                  </div>
                </template>
                <template slot-scope="scope">
                  <div>
                    <span v-if="!scope.row.isEdit">{{scope.row.percent_rating_value}}</span>
                    <el-input v-model="scope.row.percent_rating_value" v-else type="number"></el-input>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="action"
                label=""
                align="center"
                >
                <template slot="header">
                  <div style="padding-top:10px;">
                    <el-button style="background:#EB2C3E; color:white;" @click="validate('percentage')" size="mini">Add</el-button>
                  </div>
                </template>
                <template slot-scope="scope">
                  <el-tooltip content="Edit" placement="top" v-if="!scope.row.isEdit">
                    <el-button type="text" @click="edit(scope.$index, 'percentage')"><i class="el-icon-edit"></i></el-button>
                  </el-tooltip>
                  <el-tooltip content="Confirm Edit" placement="top" v-else>
                    <el-button type="text" @click="update(scope.row, 'percentage')"><i class="el-icon-check"></i></el-button>
                  </el-tooltip>
                  <el-tooltip content="Delete" placement="top" v-if="!scope.row.isEdit">
                    <el-button type="text" @click="deleteRow(scope.row.id, 'percentage')" ><i class="el-icon-delete"></i></el-button>
                  </el-tooltip>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
          <el-col :span="12">
            <el-table
              :data="percentageTable"
              v-loading="loadingPercent"
              >
              <el-table-column label="Individual Incentives" align="center">
                <el-table-column
                  prop="date"
                  label="PAR Rating"
                  align="center"
                  width="100"
                  >
                  <template slot-scope="scope">
                    <div>
                      {{ scope.row.percent_rating_from }} - {{ scope.row.percent_rating_to }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="Percent(%)" align="center">
                  <el-table-column
                    prop="percent_rating_value"
                    :label="addNoMonthsPercentage > 1 ? addNoMonthsPercentage + ' Months and up' : addNoMonthsPercentage + ' Month and up'"
                    align="center"
                    >
                  </el-table-column>
                </el-table-column>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
        <el-row>
          <h2>Absolute</h2>
        </el-row>
        <el-row :gutter="30">
          <el-col :span="12">
            <el-input v-model="addNoMonthsAbsolute" size="mini" placeholder="No of Months" style="width: 25%;" type="number"></el-input>
            <el-table
              :data="absoluteTable"
              v-loading="loadingAbsolute"
              >
             <el-table-column
                prop="absolute_rating_from"
                label="From (MPP Rating)"
                align="center"
                >
                <template slot="header" slot-scope="scope">
                  <div>
                    <span>From (MPP Rating)</span>
                    <el-input size="mini" type="number" @change="hello(scope)" v-model="addFromAbsolute"></el-input>
                  </div>
                </template>
                <template slot-scope="scope">
                  <div>
                    <span v-if="!scope.row.isEdit">{{scope.row.absolute_rating_from}}</span>
                    <el-input v-model="scope.row.absolute_rating_from" v-else type="number"></el-input>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="absolute_rating_to"
                label="To (MPP Rating)"
                align="center"
                >
                <template slot="header" slot-scope="scope">
                  <div>
                    <span>To (MPP Rating)</span>
                    <el-input size="mini" type="number" @change="hello(scope)" v-model="addToAbsolute"></el-input>
                  </div>
                </template>
                <template slot-scope="scope">
                  <div>
                    <span v-if="!scope.row.isEdit">{{scope.row.absolute_rating_to}}</span>
                    <el-input v-model="scope.row.absolute_rating_to" v-else type="number"></el-input>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="absolute_rating_value"
                label="Value"
                align="center"
                >
                <template slot="header" slot-scope="scope">
                  <div>
                    <span>Value</span>
                    <el-input size="mini" type="number" @change="hello(scope)" v-model="addValueAbsolute"></el-input>
                  </div>
                </template>
                <template slot-scope="scope">
                  <div>
                    <span v-if="!scope.row.isEdit">{{scope.row.absolute_rating_value}}</span>
                    <el-input v-model="scope.row.absolute_rating_value" v-else type="number"></el-input>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="basicsalary"
                label=""
                align="center"
                >
                <template slot="header">
                  <div style="padding-top:10px;">
                    <el-button style="background:#EB2C3E; color:white;" size="mini" @click="validate('absolute')">Add</el-button>
                  </div>
                </template>
                <template slot-scope="scope">
                  <el-tooltip content="Edit" placement="top" v-if="!scope.row.isEdit">
                    <el-button type="text" @click="edit(scope.$index, 'absolute')"><i class="el-icon-edit"></i></el-button>
                  </el-tooltip>
                  <el-tooltip content="Confirm Edit" placement="top" v-else>
                    <el-button type="text" @click="update(scope.row, 'absolute')"><i class="el-icon-check"></i></el-button>
                  </el-tooltip>
                  <el-tooltip content="Delete" placement="top" v-if="!scope.row.isEdit">
                    <el-button type="text" @click="deleteRow(scope.row.id, 'absolute')" ><i class="el-icon-delete"></i></el-button>
                  </el-tooltip>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
          <el-col :span="12">
            <el-table
              :data="absoluteTable"
              v-loading="loadingAbsolute"
              >
              <el-table-column label="Individual Incentives" align="center">
                <el-table-column
                  prop="date"
                  label="PAR Rating"
                  align="center"
                  width="100"
                  >
                  <template slot-scope="scope">
                    <div>
                      {{ scope.row.absolute_rating_from }} - {{ scope.row.absolute_rating_to }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="Absolute(%)" align="center">
                  <el-table-column
                    prop="absolute_rating_value"
                    :label="addNoMonthsAbsolute + ' to 11 Months'"
                    align="center"
                    >
                  </el-table-column>
                </el-table-column>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
    </el-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      addFromPercentage: '',
      addToPercentage: '',
      addValuePercentage: '',
      addFromAbsolute: '',
      addToAbsolute: '',
      addValueAbsolute: '',
      selectedYear: '',
      selectedEval: '',
      evaluations: [],
      yearList: [],
      percentageTable: [],
      absoluteTable: [],
      addNoMonthsPercentage: '',
      addNoMonthsAbsolute: '',
      loadingPercent: false,
      loadingAbsolute: false
    }
  },
  created () {
    this.getCompletedEval()
  },
  methods: {
    deleteRow (id, type) {
      this.$http
        .delete(type === 'percentage' ? 'mpp.evaluation-rating-percent/' + id : 'mpp.evaluation-rating-absolute/' + id)
        .then(res => {
          if (res.data.StatusCode) {
            this.$message({
              showClose: true,
              message: 'Form Submitted',
              type: 'success'
            })
            this.getPercentage()
          } else {
            this.$message({
              showClose: true,
              message: res.data.body,
              type: 'error'
            })
          }
        })
    },
    update (item, type) {
      var formSubmit = {}
      console.log(item)
      console.log(item.percent_rating_from > item.percent_rating_to)
      if (type === 'percentage') {
        if (parseInt(item.percent_rating_from) > parseInt(item.percent_rating_to)) {
          this.$message({
            showClose: true,
            message: 'Percent Rating From cannot be greater than Percent Rating To!',
            type: 'error'
          })
        } else if (item.percent_rating_from !== '' && item.percent_rating_from !== 'e' && item.percent_rating_to !== '' && item.percent_rating_to !== 'e' && item.percent_rating_value !== '' && item.percent_rating_value !== 'e') {
          formSubmit.hme_id = this.selectedEval
          formSubmit.percent_rating_from = item.percent_rating_from
          formSubmit.percent_rating_to = item.percent_rating_to
          formSubmit.percent_rating_value = item.percent_rating_value
          this.$http
            .put('mpp.evaluation-rating-percent/' + item.id, formSubmit)
            .then(res => {
              if (res.data.StatusCode) {
                this.$message({
                  showClose: true,
                  message: 'Form Submitted',
                  type: 'success'
                })
                this.getPercentage()
              } else {
                this.$message({
                  showClose: true,
                  message: res.data.body,
                  type: 'error'
                })
              }
            })
        } else {
          this.$message({
            showClose: true,
            message: 'Please complete the form before submitting!',
            type: 'error'
          })
        }
      } else {
        if (parseInt(item.absolute_rating_from) > parseInt(item.absolute_rating_to)) {
          this.$message({
            showClose: true,
            message: 'Percent Rating From cannot be greater than Percent Rating To!',
            type: 'error'
          })
        } else if (item.absolute_rating_from !== '' && item.absolute_rating_from !== 'e' && item.absolute_rating_to !== '' && item.absolute_rating_to !== 'e' && item.absolute_rating_value !== '' && item.absolute_rating_value !== 'e') {
          formSubmit.hme_id = this.selectedEval
          formSubmit.absolute_rating_from = item.absolute_rating_from
          formSubmit.absolute_rating_to = item.absolute_rating_to
          formSubmit.absolute_rating_value = item.absolute_rating_value
          this.$http
            .put('mpp.evaluation-rating-absolute/' + item.id, formSubmit)
            .then(res => {
              if (res.data.StatusCode) {
                this.$message({
                  showClose: true,
                  message: 'Form Submitted',
                  type: 'success'
                })
                this.getPercentage()
              } else {
                this.$message({
                  showClose: true,
                  message: res.data.body,
                  type: 'error'
                })
              }
            })
        } else {
          this.$message({
            showClose: true,
            message: 'Please complete the form before submitting!',
            type: 'error'
          })
        }
      }
    },
    edit (index, type) {
      if (type === 'percentage') {
        this.percentageTable[index].isEdit = true
      } else {
        this.absoluteTable[index].isEdit = true
      }
    },
    validate (type) {
      if (type === 'percentage') {
        if (parseInt(this.addFromPercentage) > parseInt(this.addToPercentage)) {
          this.$message({
            showClose: true,
            message: 'Percent Rating From cannot be greater than Percent Rating To!',
            type: 'error'
          })
        } else if (this.addFromPercentage !== '' && this.addToPercentage !== '' && this.addValuePercentage !== '' && this.selectedEval !== '') {
          if (this.addNoMonthsPercentage !== '') {
            if (this.addNoMonthsPercentage <= 11) {
              this.$message({
                showClose: true,
                message: 'Percenatage Number of Months should always be greater than 11!',
                type: 'error'
              })
            } else {
              this.executeAdd(type)
            }
          } else {
            this.executeAdd(type)
          }
        } else {
          this.$message({
            showClose: true,
            message: 'Please complete the form before submitting!',
            type: 'error'
          })
        }
      } else {
        if (parseInt(this.addFromAbsolute) > parseInt(this.addToAbsolute)) {
          this.$message({
            showClose: true,
            message: 'Absolute Rating From cannot be greater than Percent Rating To!',
            type: 'error'
          })
        } else if (this.addFromAbsolute !== '' && this.addToAbsolute !== '' && this.addValueAbsolute !== '' && this.selectedEval !== '') {
          if (this.addNoMonthsAbsolute !== '') {
            if (this.addNoMonthsAbsolute > 11) {
              this.$message({
                showClose: true,
                message: 'Absolute Number of Months should always be less than or equal to 11!',
                type: 'error'
              })
            } else {
              this.executeAdd(type)
            }
          } else {
            this.executeAdd(type)
          }
        } else {
          this.$message({
            showClose: true,
            message: 'Please complete the form before submitting!',
            type: 'error'
          })
        }
      }
    },
    executeAdd (type) {
      var formSubmit = {}
      if (type === 'percentage') {
        formSubmit.hme_id = this.selectedEval
        formSubmit.percent_no_of_months = this.addNoMonthsPercentage
        formSubmit.percent_rating_from = this.addFromPercentage
        formSubmit.percent_rating_to = this.addToPercentage
        formSubmit.percent_rating_value = this.addValuePercentage
        this.$http
          .post('mpp.evaluation-rating-percent', formSubmit)
          .then(res => {
            if (res.data.StatusCode) {
              this.$message({
                showClose: true,
                message: 'Form Submitted',
                type: 'success'
              })
              this.getPercentage()
              this.addNoMonthsPercentage = ''
              this.addFromPercentage = ''
              this.addToPercentage = ''
              this.addValuePercentage = ''
            } else {
              this.$message({
                showClose: true,
                message: res.data.body,
                type: 'error'
              })
            }
          })
      } else {
        formSubmit.hme_id = this.selectedEval
        formSubmit.absolute_no_of_months = this.addNoMonthsAbsolute
        formSubmit.absolute_rating_from = this.addFromAbsolute
        formSubmit.absolute_rating_to = this.addToAbsolute
        formSubmit.absolute_rating_value = this.addValueAbsolute
        this.$http
          .post('mpp.evaluation-rating-absolute', formSubmit)
          .then(res => {
            if (res.data.StatusCode) {
              this.$message({
                showClose: true,
                message: 'Form Submitted',
                type: 'success'
              })
              this.getPercentage()
              this.addNoMonthsAbsolute = ''
              this.addFromAbsolute = ''
              this.addToAbsolute = ''
              this.addValueAbsolute = ''
            } else {
              this.$message({
                showClose: true,
                message: res.data.body,
                type: 'error'
              })
            }
          })
      }
    },
    hello (hello) {
      console.log(hello)
    },
    getPercentage () {
      this.loadingPercent = true
      this.loadingAbsolute = true
      this.percentageTable = []
      this.absoluteTable = []
      this.$http
        .get('mpp.evaluation-rating-percent-evaluation/' + this.selectedEval)
        .then(res => {
          if (res.data.StatusDescription === 'Sorry! Nothing was found.') {
            this.percentageTable = []
            this.absoluteTable = []
            this.loadingPercent = false
            this.getAbsolute()
          } else {
            res.data.body.forEach(e => {
              this.$set(e, 'isEdit', false)
            })
            if (res.data.body.length > 1) {
              this.addNoMonthsPercentage = res.data.body[0].percent_no_of_months
            }
            this.percentageTable = res.data.body
            this.loadingPercent = false
            this.getAbsolute()
          }
        })
    },
    getAbsolute () {
      this.$http
        .get('mpp.evaluation-rating-absolute-evaluation/' + this.selectedEval)
        .then(res => {
          if (res.data.StatusDescription === 'Sorry! Nothing was found.') {
            console.log('nothing')
            this.absoluteTable = []
            this.loadingAbsolute = false
          } else {
            res.data.body.forEach(e => {
              this.$set(e, 'isEdit', false)
            })
            if (res.data.body.length > 1) {
              this.addNoMonthsAbsolute = res.data.body[0].absolute_no_of_months
            }
            this.absoluteTable = res.data.body
            this.loadingAbsolute = false
          }
        })
    },
    getEval () {
      this.selectedEval = []
      this.percentageTable = []
      this.absoluteTable = []
      this.$http
        .get('mpp.evaluation-list-by-year/' + this.selectedYear)
        .then(res => {
          this.evaluations = res.data.body
        })
    },
    getCompletedEval () {
      this.$http
        .get('mpp-completed-evaluation-list')
        .then(res => {
          var uniqueData = [...res.data.body.reduce((map, obj) => map.set(obj.year, obj), new Map()).values()]
          this.yearList = uniqueData
        })
    }
  }
}
</script>
<style>
  .el-table thead.is-group th.el-table__cell {
    background: none;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
</style>
